import { useEffect, useState } from "react";

import adsRefs from "@/feature-ads/refs.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const pathReg = /\/(profile|in-game|match|video-ad-test)/;
const inGamePathReg = /\/(in-game)/;
const profilePathReg = /\/(profile)\//;

const useShouldShowAdVideoContainer = () => {
  const { showIngameVideo, showProfileVideo } = useSnapshot(adsRefs);
  const route = useRoute((a, b) => a?.currentPath === b?.currentPath, {
    isGlobal: true,
  });

  const [show, setShow] = useState(false);
  // Both in-game and profile need to determine whether to display video ads based on the CMS settings
  useEffect(() => {
    if (!showIngameVideo && inGamePathReg.test(route.currentPath)) {
      setShow(false);
    } else if (!showProfileVideo && profilePathReg.test(route.currentPath)) {
      setShow(false);
      // As long as the user enters a page that can display the video,
      //  it will always be visible and will not be removed. (exclude in-game/profile, they will be controlled by feature flag)
    } else if (pathReg.test(route.currentPath)) {
      setShow(true);
    }
  }, [route?.currentPath, showIngameVideo, showProfileVideo]);

  return show;
};

export default useShouldShowAdVideoContainer;
