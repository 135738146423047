import React, { useMemo } from "react";

import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import { simplifyPath } from "@/feature-ads/display-events.mjs";
import { VIDEO_AD_CONTAINER_ID } from "@/feature-ads-video-new/constants.mjs";
import useShouldShowAdVideoContainer from "@/feature-ads-video-new/useShouldShowVideoAd.jsx";
import { useGameSymbol } from "@/util/game-route.mjs";
import { useRoute } from "@/util/router-hooks.mjs";

const AdVideoContainer = () => {
  const { currentPath } = useRoute({ isGlobal: true });
  const show = useShouldShowAdVideoContainer();
  const simplePath = useMemo(() => simplifyPath(currentPath), [currentPath]);
  const gameSymbol = useGameSymbol();
  const gameName = GAME_SHORT_NAMES[gameSymbol];

  if (!show) return null;

  return (
    <div
      id={VIDEO_AD_CONTAINER_ID}
      data-page-macro={gameName}
      data-route={simplePath}
    ></div>
  );
};

export default AdVideoContainer;
